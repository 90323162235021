import axios from "@/plugins/axios";

export default {
  
  async relatorioArrecadacaoDiaria (dados) {
    return await axios.post('relatorios/arrecadacoes-diarias', dados)
  },
  async relatorioMovimentacaoVeiculo (dados) {
    return await axios.post('relatorios/movimentacoes-veiculos', dados)
  },

  async relatorioArrecadacaoCaixaOperador (dados) {
    return await axios.post('relatorios/arrecadacao-caixa-operador', dados)
  },

  async relatorioFechamentoOperador (dados) {
    return await axios.post('relatorios/fechamentos-operadores', dados)
  },
  
  
  
  
  
  
  
  
  
  
  
  
  async teste1() {
    return [
      {
        data: "01/08/2021",
        valor: "1.000,05",
        tipo: "Dinheiro",
      },
      {
        data: "08/08/2021",
        valor: "4.000,05",
        tipo: "Dinheiro",
      },
      {
        data: "15/08/2021",
        valor: "6.000,05",
        tipo: "Dinheiro",
      },
      {
        data: "01/08/2021",
        valor: "1.500,05",
        tipo: "Débito",
      },
      {
        data: "08/08/2021",
        valor: "2.500,05",
        tipo: "Débito",
      },
      {
        data: "15/08/2021",
        valor: "3.500,05",
        tipo: "Débito",
      },
      {
        data: "01/08/2021",
        valor: "500,05",
        tipo: "Crédito",
      },
      {
        data: "08/08/2021",
        valor: "800,05",
        tipo: "Crédito",
      },
      {
        data: "15/08/2021",
        valor: "900,05",
        tipo: "Crédito",
      },
    ];
  },
  async teste2() {
    return [
      {
        login: "0180",
        nome: "Exemplo1",
        ticket: "R$20,00",
      },
      {
        login: "0758",
        nome: "Exemplo2",
        ticket: "R$15,00",
      },
      {
        login: "1520",
        nome: "Exemplo3",
        ticket: "R$12,00",
      },
      {
        login: "0553",
        nome: "Exemplo4",
        ticket: "R$05,00",
      },
      {
        login: "1550",
        nome: "Exemplo5",
        ticket: "R$30,00",
      },
    ];
  },
  async fechamentoCaixa() {
    return [
      {
        id: "0180",
        nome: "Exemplo1",
        qntidade: "5",
        dinheiro: "R$30,00",
        credito: "R$2,00",
        debito: "R$9,00",
        total: "R$41,00",
      },
      {
        id: "0758",
        nome: "Exemplo2",
        qntidade: "11",
        dinheiro: "R$30,00",
        credito: "R$5,00",
        debito: "R$8,00",
        total: "R$43,00",
      },
      {
        id: "1520",
        nome: "Exemplo3",
        qntidade: "12",
        dinheiro: "R$30,00",
        credito: "R$8,00",
        debito: "R$6,00",
        total: 'R$44,00'
      },
      {
        id: "0553",
        nome: "Exemplo4",
        qntidade: "15",
        dinheiro: "R$30,00",
        credito: "R$1,00",
        debito: "R$23,00",
        total: 'R$54,00'
      },
      {
        id: "1550",
        nome: "Exemplo5",
        qntidade: "2",
        dinheiro: "R$30,00",
        credito: "R$9,00",
        debito: "R$21,00",
        total: 'R$60,00'
      },
    ];
  },
};
